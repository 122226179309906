.user_detail{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.imageLogo{
    width: 100%;
    max-width: 350px;
    margin-bottom: 0;
}

.imageLogo img{
    width: 100%;
    height: 100%;
}